body {
  height: 100%;
}

html {
  font-family: 'Roboto', sans-serif;
  height: 100%;
  -webkit-text-size-adjust: 100%;
}

ol {
  padding-left: 1.4em;
}

ul {
  padding-left: 1.2em;
}​

.hide-placeholder .public-DraftEditorPlaceholder-inner {
  display: none
}